const messages_en = {
	'login.welcome': 'Welcome, sign up',
	'logout.title': 'Logout',

	'commons.insurer': 'Insurer',
	'commons.reinsurer': 'Reinsurer',

	'commons.back': 'Back',
	'commons.save': 'Save',
	'commons.CreateQuote': 'CREATE QUOTE',
	'commons.validate': 'Validate',
	'commons.update': 'Update',
	'commons.rowsPerPage': 'Rows per page',
	'commons.of': 'of',
	'commons.noData': 'Data not found',
	'commons.step': 'Step',
	'commons.filter': 'Filter...',
	'commons.dragAndDrop': 'Click or drag and drop the file here',
	'commons.findNumber': 'Find number',
	'commons.initDate': 'Init date',
	'commons.endDate': 'End date',
	'commons.downloadAll': 'Download all',
	'commons.consolidated': 'Consolidated',
	'commons.status': 'Status',
	'commons.apply': 'Apply',
	'commons.credits': 'Credits',
	'commons.next': 'Next',
	'commons.download': 'Download',
	'commons.have': 'You have',
	'commons.error': 'error(s)',
	'commons.required': 'Required field',
	'commons.textQuantity': 'Number of characters:',
	'commons.start': 'Start',
	'commons.daily': 'Daily',
	'commons.weekly': 'Weekly',
	'commons.biweekly': 'Biweekly',
	'commons.monthly': 'Monthly',
	'commons.company_supervisor': 'Company supervisor',
	'commons.customer': 'Customer',
	'commons.downloading': 'Downloading',
	'commons.root': 'root',
	'commons.add': 'Add',
	'commons.yes': 'Yes',
	'commons.contactUs': 'Contact us',
	'commons.suscribeMe': 'Subscribe',
	'commons.goToPay': 'Go to pay',
	'commons.mxn': 'MXN',
	'commons.customize': 'CUSTOMIZE QUOTE',

	'month.enero': 'January',
	'month.febrero': 'February',
	'month.marzo': 'March',
	'month.abril': 'April',
	'month.mayo': 'May',
	'month.junio': 'June',
	'month.julio': 'July',
	'month.agosto': 'August',
	'month.septiembre': 'September',
	'month.octubre': 'October',
	'month.noviembre': 'November',
	'month.diciembre': 'December',

	'table.name': 'NAME',
	'table.lastName': 'LAST NAME',
	'table.email': 'EMAIL',
	'table.phone': 'PHONE',
	'table.membership': 'MEMBERSHIP NUMBER',
	'table.createdAt': 'MEMBER SINCE',
	'table.allySince': 'ALLY SINCE',
	'table.guideSince': 'GUIDE SINCE',
	'table.membershipType': 'MEMBERSHIP TYPE',
	'table.police': 'POLICE',
	'table.MEMBERSHIPPDF': 'MEMBERSHIP PDF',
	'table.options': 'OPTIONS',
	'table.seller': 'WILMA GUIDE',
	'table.admin': 'ADMIN',
	'table.status': 'STATUS',
	'table.actions': 'Actions',
	'table.date': 'DATE',
	'table.memberId': 'MEMBER ID',
	'table.guideId': 'GUIDE ID',
	'table.allyId': 'ALLY ID',
	'table.ally': 'WILMA ALLY',
	'table.discount': 'DISCOUNT',
	'table.company': ' MEMBER NAME',
	'table.member': 'MEMBER',
	'table.insuredAmount': 'INSURED AMOUNT',
	'table.premiumAmount': 'TOTAL PREMIUM AMOUNT',
	'table.city': 'CITY',
	'table.premium': 'PREMIUM',
	'table.folio': 'FOLIO',
	'table.quotes': 'NUMBER OF QUOTES',
	'table.active.quotes': 'CUURENT QUOTES',
	'table.inactive.quotes': 'EXPIRED QUOTES',
	'table.memberships': 'MEMBERSHIPS',
	'table.last.login': 'LAST LOGIN',
	'table.dateOfCreation': 'DATE OF CREATION',
	'table.dateOfQuote': 'QUATATION DATE',
	'table.accionsTitle': 'ACCIONS',
	'table.reactive': 'Reactive',
	'table.policyReactivated': 'Policy reactivated',
	'table.policyReactivateError': 'Error reactivating policy',
	'table.Active': 'CURRENT QUOTES',
	'table.Inactive': 'EXPIRED QUOTES',

	'opt.delete': 'Delete',
	'opt.edit': 'Edit',
	'opt.credits': 'Add credits',
	'opt.view': 'View',
	'opt.downloadReport': 'Download report',
	'opt.downloadAudio': 'Download audio',
	'opt.downloadFile': 'Download file',
	'opt.clone': 'Clone campaign',
	'opt.moreThan100': 'More than 100',
	'opt.to': 'to',
	'opt.businessLine.1': 'Lodging/Restaurants and Events',
	'opt.businessLine.2': 'Transportation',
	'opt.businessLine.3': 'Tour Operator and Experience Provider',
	'opt.businessLine.4': 'Beach Services',
	'opt.businessLine.5': 'Vacation Property Rentals',
	'opt.businessLine.6': 'Real Estate and Property Management',
	'opt.businessLine.7': 'Culture',
	'opt.businessLine.8': 'Health Services',
	'opt.businessLine.9': 'Consulting',
	'opt.businessLine.10': 'Commercial spaces',
	'opt.businessLine.12': 'Residential property',
	'opt.businessLine.11': 'Others',
	'opt.customized': 'Customized',
	'opt.select': 'Select',

	'dashboard.title': 'Dashboard',
	'dashboard.members': 'Members',
	'dashboard.addMember': 'Add member',
	'dashboard.sellers': 'Wilma Guides',
	'dashboard.addGuide': 'Add guide',
	'dashboard.memberships': 'Memberships',
	'dashboard.addQuote': 'Create a customized quotation',
	'dashboard.addAlly': 'Add ally',
	'dashboard.Calculator': 'QUOTE CALCUATOR',

	'police.limitText': 'Maximum Limit of Indemnity',
	'police.premium': 'Premium',
	'police.expeditionAmount': 'Expedition expenses',
	'police.iva': 'VAT',
	'police.resume': 'Membership summary',
	'police.contractor': 'Policyholder',

	'forms.loginTitle': 'Login',
	'forms.login': 'Login',
	'forms.ChangePassword': 'Change Password.',
	'forms.welcomeMessage': 'Log in to resume your applications or manage your memberships.',
	'forms.userName': 'User name',
	'forms.userNameLabel': 'Enter your user name',
	'forms.name': 'Name',
	'forms.nameLabel': 'Enter your name',
	'forms.lastName': 'Last name',
	'forms.lastNameLabel': 'Enter your last name',
	'forms.gender': 'Select your gender',
	'forms.age': 'Age',
	'forms.ageLabel': 'Enter your age',
	'forms.whatsapp': 'Phone number',
	'forms.whatsappLabel': 'Enter your Phone number',
	'forms.email': 'Email',
	'forms.emailLabel': 'Enter your email',
	'forms.EnterYourEmailAddress': 'Enter your email address.',
	'forms.password': 'Password',
	'forms.passwordLabel': 'Enter your password',
	'forms.confirmPassword': 'Confirm your password',
	'forms.EnterYourNewPassword': 'Enter your new password.',
	'forms.forgetPassword': 'Recover / Change Password',
	'forms.wilmaGuide': 'Wilma Guide (optional)',
	'forms.wilmaGuideLabel': 'Enter your Wilma guide code',
	'forms.register': 'Register',
	'add.register': 'Register',
	'forms.newUser': 'New user',
	'forms.tellUs': 'Tell us more about yourself',
	'forms.nameCorporate': 'Name or corporate name',
	'forms.contractor': 'Contracting Party',
	'forms.contractorLabel': 'Enter the name of the contracting party',
	'forms.streetAndNumber': 'Street and number',
	'forms.streetAndNumberLabel': 'Enter your street and number',
	'forms.state': 'State',
	'forms.companyCity': 'Population/municipality',
	'forms.companyCityLabel': 'Enter your population/municipality',
	'forms.colony': 'Neighborhood',
	'forms.colonyLabel': 'Enter your neighborhood',
	'forms.zipCode': 'Zip Code',
	'forms.zipCodeLabel': 'Enter your zip code',
	'forms.rfc': 'Passport Number',
	'forms.rfcLabel': 'Enter your passport number',
	'forms.activity': 'Activity',
	'forms.businessLine': 'Select your activity',
	'forms.commercialName': 'Business name',
	'forms.commercialNameLabel': 'Enter your legal business name',
	'forms.numberOfEmployees': 'Number of employees',
	'forms.largeRevenue': 'Is your annual turnover revenue greater than $235,000,000 MXN?',
	'forms.largeRevenueM1':
		'Our membership is focused on helping the SME sector, please contact one of our consultants for a tailor-made solution.',
	'forms.largeRevenueM2':
		'If you are interested in acquiring one of our memberships, please contact us for more information.',
	'forms.largeRevenueM3': 'You can leave the password blank if you do not need to change it.',
	'forms.chooseMembership': 'Choose one of our memberships',
	'forms.selectCity': 'SELECT A CITY',
	'forms.selectMembership': 'SELECT A WILMA MEMBERSHIP',
	'forms.MINIMUMAMOUNT': 'MINIMUM AMOUNT',
	'forms.MAXIMUMAMOUNT': 'MAXIMUM AMOUNT',
	'forms.compensationLimit': 'Select Maximum Limit of Indemnity',
	'forms.compensationLimitLabel': 'Maximum Limit of indemnity of your membership',
	'forms.recover': 'Recover',
	'forms.selectSeller': 'guide',
	'forms.partner': 'Ally',
	'forms.partnerLabel': 'Log in to the assigned ally',
	'forms.capacity': 'Capacity',
	'forms.capacityLabel': 'Enter capacity',
	'forms.netPremium': 'Net Premium Goal',
	'forms.netPremiumlabel': 'Net Premium Income',
	'forms.memberships': 'Memberships Goal',
	'forms.membershipsLabel': 'Login Memberships',
	'forms.registerForm': 'Register',
	'forms.editGuide': 'Edit guide',
	'forms.editAlly': 'Edit ally',
	'forms.editMember': 'Edit member',

	'errors.invalidNumber': 'Must be a valid number',
	'errors.invalidEmail': 'Must be a valid email',
	'errors.invalidPassword': 'Password must contain at least 6 characters',
	'errors.required': 'This field is required',
	'errors.minimun': 'The minimum amount is $100,000',
	'errors.maximum': 'The maximum amount is $7,000,000',

	'label.male': 'Male',
	'label.female': 'Female',
	'label.other': 'I prefer not to say',

	'msg.termsAndConditions':
		'I confirm, under oath, that the data is correct and that the Contracting Party has assets or an economic activity that may be affected by a hurricane within the selected Coverage Area.',
	'msg.forgotPasswordTitle': 'Recover / Change Password',
	'msg.forgotPassword':
		'Enter your email, and we will send you a link to recover or change your password.',
	'msg.newUserTitle': 'Join the Wilma community!',
	'msg.newUser': 'Enter the following data',
	'msg.shortTerms': 'I accept the terms and conditions',
	'msg.seeTerms': 'See terms and conditions',

	'account.title': 'My account',
	'account.membership': 'My memberships',
	'account.noMembership': 'You do not have active memberships',
	'account.completeProcess': 'Complete process',
	'account.noProcess': 'You do not have incomplete processes',
	'account.welcome': 'Welcome',
	'account.validFrom': 'Valid from',
	'account.validUntil': 'Valid until',
	'account.policy': 'Policy',

	'members.title': 'Members',

	'guides.title': 'Wilma Guides',

	'memberships.title': 'Memberships',

	'allies.title': 'Allies',

	'quotes.title': 'Quotes',

	'payments.manual': 'Manual Payment',

	'claims.title': 'Claims',

	'payment.over': 'Pay over ',
	'payment.months': 'months with no interest',
	'payment.with': 'with CC',

	'dashboard.clipboardLink': 'Link copied to clipboard!',
	'dashboard.clipboardBottom': 'COPY YOUR REFERRAL LINK!',
	'message.YouMustConfirmThatTheDataIsCorrect': 'You must confirm that the data is correct.',
	'message.AnEmailHasBeenSentWithTheLinkToResetYourPassword':
		'An email has been sent with the link to reset your password.',
	'message.RequiredField': 'Required field.',
	'message.Minimum10Characters': 'Minimum 10 characters.',
	'message.Maximum10Characters': 'Maximum 10 characters.',
	'message.TheEmailIsAlreadyRegistered': 'The email is already registered.',
	'message.AnErrorOccurredWhileSendingTheEmailPleaseTryAgain':
		'An error occurred while sending the email, please try again.',
	'message.InvalidEmail': 'Invalid email.',
	'message.Minimum6Characters': 'Minimum 6 characters.',
	'message.ThePasswordsMustMatch': 'The passwords must match.',
	'message.OnlyNumbersAreAllowed': 'Only numbers are allowed.',
	'message.GuideCreatedSuccessfully': 'Guide created successfully.',
	'message.UnexpectedError': 'Unexpected error',
	'message.ReferenceNotFound': 'Reference not found.',
	'message.ThePolicyIsAlreadyActive': 'The policy is already active.',
	'message.EnterThePaymentID': 'Enter the payment ID.',
	'message.PaymentID': 'Payment ID',
	'message.MemberEditedSuccessfully': 'Member edited successfully.',
	'message.PasswordSuccessfullyChangedPleaseLogIn': 'Password successfully changed. Please log in.',
	'message.AnErrorHasOccurredPleaseTryAgain.': ' An error has occurred, please try again.',
	'message.PaymentUpdatedSuccessfully': 'Payment updated successfully.',
	'message.WilmaMemberCreatedSuccessfully': 'Wilma Member created successfully.',
	'message.QuotationSaved': 'Quotation saved.',
	'message.TheProvidedEmailIsAlreadyRegisteredButNotAsAcustomer':
		'The provided email is already registered, but not as a customer.',
	'message.ThePhoneNumberIsAlreadyRegistered': 'The phone number is already registered.',
	'messagae.IncorrectGuidePleaseContactWilma': 'Incorrect guide, please contact Wilma.',
	'message.ErrorGeneratingPaymentLink': 'Error generating payment link.',
	'title.NetPremiumTarget': 'NET PREMIUM TARGET',
	'title.MembershipTarjet': 'MEMBERSHIP TARGET',
	'title.TarjetInsuredAmount': 'TARGET INSURED AMOUNT',
	'dashboard.membershipSold': 'MEMBERSHIP SOLD',
	'dashboard.ALLIES': 'ALLIES',
	'dashboard.GUIDES': 'GUIDES',
	'dashboard.MEMBERS': 'MEMBERS',
	'dashboard.QUOTES': 'QUOTES',
	'members.createMember': 'CREATE MEMBER',
};
export default messages_en;
