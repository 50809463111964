export const convertCity = city => {
	switch (city) {
		case '1':
			return 'Riviera Nayarit - Puerto Vallarta';
		case '2':
			return 'Los Cabos';
		case '3':
			return 'Cozumel';
		case '4':
			return 'Cancún';
		case '5':
			return 'Playa del Carmen';
		case '6':
			return 'Tulum';
		case '7':
			return 'Veracruz';
		case '8':
			return 'Puerto Escondido';
		case '9':
			return 'Mérida - Puerto Progreso';
		case '10':
			return 'Mazatlán';
		case '11':
			return 'Acapulco';
		case '12':
			return 'Acapulco Diamante';
		default:
			return null;
	}
};
export const convertMembership = membership => {
	switch (membership) {
		case '1':
			return 'Sandy';
		case '2':
			return 'Michelle';
		case '3':
			return 'Katrina';
		case '4':
			return 'Wilma';
		default:
			return null;
	}
};

export const convertBusinessLine = businessLine => {
	switch (businessLine) {
		case '1':
			return 'Hospedaje/Restaurantes y Eventos';
		case '2':
			return 'Transporte';
		case '3':
			return 'Tour Operador y Proveedor de Experiencias';
		case '4':
			return 'Servicios en Playa';
		case '5':
			return 'Renta de Propiedades Vacacionales';
		case '6':
			return 'Bienes Raices y Administración de Propiedades';
		case '7':
			return 'Cultura';
		case '8':
			return 'Servicios de Salud';
		case '9':
			return 'Asesoria';
		case '10':
			return 'Locales Comerciales';
		case '11':
			return 'Otros';
		case '12':
			return 'Casa Habitación';
		default:
			return null;
	}
};

const percentagesByCity = {
	'Los Cabos': 0.0916290,
	'Riviera Nayarit - Puerto Vallarta': 0.0876697,
	Cancún: 0.09502264,
	'Playa del Carmen': 0.1176470,
	Tulum: 0.1197210,
	Cozumel: 0.1340498,
	'Veracruz': 0.1341139,
	'Puerto Escondido': 0.0838990,
	'Mérida - Puerto Progreso': 0.1167044,
	'Mazatlán': 0.1341139,
	'Acapulco': 0.1341139,
	'Acapulco Diamante': 0.0904977,
};
function percentagesByCityWithDiscount(discount) {
	return 1 - discount / 100;
}

export const calculatePrice = (city, amount) => {
	const somus = 0.98;
	const pnr = (amount * percentagesByCity[convertCity(city)]) / 100;
	const somusAmount = Number((pnr / somus).toFixed(2));
	const PNS = Number((somusAmount / (1 - 0.03 - 0.09 - 0.05)).toFixed(2));
	const subtotal = Number(((PNS + 45) * 1.16).toFixed(2));
	const total = Number((subtotal * 1.035).toFixed(2));
	return total;
};

export const calculatePriceNew = (city, compensationLimit, discount = 0) => {
	const cuotaCliente =
		percentagesByCity[convertCity(city)] * percentagesByCityWithDiscount(discount);
	const primaNeta = Number((compensationLimit * cuotaCliente).toFixed(2));
	const gastosDeExpedicion = 400;
	const primaPlusGastos = primaNeta + gastosDeExpedicion;
	const iva = primaPlusGastos * 0.16;
	const primaTotal = Number((primaPlusGastos + iva).toFixed(2));
	return {
		cuotaCliente,
		primaNeta,
		gastosDeExpedicion,
		primaPlusGastos,
		iva,
		primaTotal,
	};
};
