import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { calculatePriceNew } from 'utils/formUtils';
import formatNumber from 'utils/formatNumber';
import { useIntl } from 'react-intl';
const MembershipData = props => {
	const { city, membership, compensationLimit, discount, setFieldValue, values } = props;
	const [primaReal, setPrimaReal] = useState(null);
	const { formatMessage } = useIntl();
	useEffect(() => {
		if (city !== '' && membership !== '') {
			const prices = calculatePriceNew(city, compensationLimit, discount);
			setFieldValue('montoFinanciamiento', compensationLimit);
			setFieldValue('gastosDeExpedicion', prices.gastosDeExpedicion);
			setFieldValue('IVA', prices.iva);
			setFieldValue('primaNeta', prices.primaNeta);
			setFieldValue('primaTotal', prices.primaTotal);
			setFieldValue('primaPrimerPago', prices.primaTotal);
			setFieldValue('primaPagosSucesivos', 0);
			const pricesWithDiscount = calculatePriceNew(city, compensationLimit, 0);
			setPrimaReal(pricesWithDiscount.primaTotal);
		}
	}, [city, membership, discount, compensationLimit]);
	const membershipNames = ['sandy', 'michelle', 'katrina', 'wilma'];
	const limitCost = ['250000', '500000', '650000', 'Ajustable'];
	return (
		<>
			{/* {membership === '' && <span>{formatMessage({ id: 'forms.selectMembership' })}</span>} */}
			<Icon>
				{membership !== '' && (
					<img
						src={`/images/membership/${membershipNames[membership - 1]}.jpg`}
						alt={membershipNames[membership - 1]}
					/>
				)}
			</Icon>
			{membership !== '' && city !== '' && values.compensationLimit >= 100000 && (
				<>
					<Data>
						<p>{formatMessage({ id: 'police.limitText' })}</p>
						<span>{`$${formatNumber(values.compensationLimit)} ${formatMessage({ id: 'commons.mxn' })}`}</span>
					</Data>
					<Data>
						<p>{formatMessage({ id: 'police.premium' })}</p>
						<Prima>
							<span>{`$${formatNumber(values.primaNeta)} ${formatMessage({ id: 'commons.mxn' })}`}</span>
						</Prima>
					</Data>
					<Data>
						<p>{formatMessage({ id: 'police.expeditionAmount' })}</p>
						<span>{`$${formatNumber(400)} ${formatMessage({ id: 'commons.mxn' })}`}</span>
					</Data>
					<Data>
						<p>{formatMessage({ id: 'police.iva' })}</p>
						<span>{`$${formatNumber(values.IVA)} ${formatMessage({ id: 'commons.mxn' })}`}</span>
					</Data>
					<Data>
						<p>Total</p>
						<Prima>
							{discount > 0 && (
								<span
									style={{ textDecoration: 'line-through' }}>{`$${formatNumber(primaReal)}`}</span>
							)}
							<span>{`$${formatNumber(values.primaTotal)} ${formatMessage({ id: 'commons.mxn' })}`}</span>
						</Prima>
					</Data>
					{values.primaTotal < 200000 && (
						<MSI>
							<p></p>
							<Prima>
								<div></div>
								<p>
									{formatMessage({ id: 'payment.over' })}{' '}
									<b>6 {formatMessage({ id: 'payment.months' })}</b>
								</p>
								<span>
									{`$${formatNumber(values.primaTotal / 6)} ${formatMessage({ id: 'commons.mxn' })}`}
									{` `}
									{formatMessage({ id: 'payment.with' })}
								</span>
							</Prima>
						</MSI>
					)}
				</>
			)}
		</>
	);
};
const Icon = styled.div`
	margin: 1rem 0;
	img {
		display: block;
		width: 90px;
	}
`;
const Data = styled.div`
	margin-bottom: 1rem;
	display: flex;
	justify-content: space-between;
	padding-bottom: 1rem;
	border-bottom: 1px solid ${({ theme }) => theme.secondaryColor};
	p {
		font-size: 1.1rem;
		color: ${({ theme }) => theme.secondaryColor};
	}
	span {
		font-weight: 700;
		font-size: 1.3em;
		color: ${({ theme }) => theme.primaryColor};
	}
`;
const Prima = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
`;

const MSI = styled.div`
	margin-bottom: 1rem;
	display: flex;
	justify-content: space-between;
	padding-bottom: 1rem;
	font-size: 1.1rem;
	color: ${({ theme }) => theme.primaryColor};
	align-items: flex-end;
	text-align: right;
	span,
	b {
		font-weight: 700;
		font-size: 1.3em;
	}
`;
export default MembershipData;
