import styled from 'styled-components';
import MapData from './mapData';
import { useIntl } from 'react-intl';

const Map = props => {
	const { formatMessage } = useIntl();
	const {
		citys,
		partnerCount,
		guideCount,
		membersCount,
		quotes,
		setRole,
		section,
		role,
		roleLv1,
		roleLv2,
		roleLv3,
		roleLv4,
	} = props;

	const roles = [roleLv1, roleLv2, roleLv3, roleLv4];

	const getLastRole = roles => {
		for (let i = roles.length - 1; i >= 0; i--) {
			if (roles[i]) {
				return roles[i];
			}
		}
		return null;
	};

	const lastRole = getLastRole(roles);

	return (
		<Wrap>
			<Header>{formatMessage({ id: 'dashboard.membershipSold' })}</Header>
			<IconsColumn>
				{lastRole === 'root' && (
					<IconData onClick={() => setRole('partner')}>
						<h3>{partnerCount}</h3>
						<img
							src="/images/map/allies.png"
							alt="Aliados"
						/>
						<h3>{formatMessage({ id: 'dashboard.ALLIES' })}</h3>

						{role === '' && (
							<Tooltip>
								{section.map((user, index) => (
									<p
										key={index}
										style={{
											background: index % 2 === 0 ? '#f0f0f0' : 'transparent',
										}}>
										{user.name}
									</p>
								))}
							</Tooltip>
						)}
					</IconData>
				)}
				{(lastRole === 'partner' || lastRole === 'root') && (
					<IconData onClick={() => setRole('seller')}>
						<h3>{guideCount}</h3>
						<img
							src="/images/map/guides.png"
							alt="Aliados"
						/>
						<h3>{formatMessage({ id: 'dashboard.GUIDES' })}</h3>
						{role === 'seller' && (
							<Tooltip>
								{section.map((user, index) => (
									<p
										key={index}
										style={{
											background: index % 2 === 0 ? '#f0f0f0' : 'transparent',
										}}>
										{user.name}
									</p>
								))}
							</Tooltip>
						)}
					</IconData>
				)}
			</IconsColumn>
			<MapBackground>
				<MapData
					x={350}
					y={330}
					value={citys.find(city => city.city === '1')?.polices || 0}
				/>
				<MapData
					x={190}
					y={190}
					value={citys.find(city => city.city === '2')?.polices || 0}
				/>
				<MapData
					x={750}
					y={305}
					value={citys.find(city => city.city === '3')?.polices || 0}
				/>
				<MapData
					x={680}
					y={270}
					value={citys.find(city => city.city === '4')?.polices || 0}
				/>
				<MapData
					x={700}
					y={310}
					value={citys.find(city => city.city === '5')?.polices || 0}
				/>
				<MapData
					x={670}
					y={350}
					value={citys.find(city => city.city === '6')?.polices || 0}
				/>
			</MapBackground>
			<IconsColumn>
				{lastRole === 'seller' || lastRole === 'root' || lastRole === 'partner' ? (
					<IconData onClick={() => setRole('client')}>
						<h3>{membersCount}</h3>
						<img
							src="/images/map/members.png"
							alt="Aliados"
						/>
						<h3>{formatMessage({ id: 'dashboard.MEMBERS' })}</h3>
						{role === 'client' && (
							<Tooltip>
								{section.map((user, index) => (
									<p
										key={index}
										style={{
											background: index % 2 === 0 ? '#f0f0f0' : 'transparent',
										}}>
										{user.name}
									</p>
								))}
							</Tooltip>
						)}
					</IconData>
				) : null}
				<IconData onClick={() => setRole('quotes')}>
					<h3>{quotes}</h3>
					<img
						src="/images/map/quotes.png"
						alt="Aliados"
					/>
					<h3>{formatMessage({ id: 'dashboard.QUOTES' })}</h3>
				</IconData>
			</IconsColumn>
		</Wrap>
	);
};

const Wrap = styled.div`
	margin-top: 4rem;
	background-color: #94d3d0;
	padding: 1rem;
	min-height: 500px;
	display: grid;
	grid-template-columns: 1fr 800px 1fr;
	grid-gap: 1rem;
	position: relative;
`;

const Header = styled.h1`
	position: absolute;
	top: 1rem;
	width: 100%;
	text-align: center;
	color: white;
	font-size: 1.5rem;
	font-weight: bold;
	margin: 0;
`;

const MapBackground = styled.div`
	background: url(/images/map.png) no-repeat center center;
	background-size: cover;
	position: relative;
`;

const IconsColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1rem 0;
`;

const IconData = styled.button`
	color: #fff;
	background-color: transparent;
	border: none;
	position: relative;
	cursor: pointer;
	h3 {
		font-size: 1.5rem;
		text-align: center;
	}
	img {
		display: block;
		width: 100%;
		margin: 0 auto;
		max-width: 100px;
	}

	&:hover {
		& > div {
			opacity: 1;
			visibility: visible;
			transform: translate(-50%, 120%);
		}
	}
`;

const Tooltip = styled.div`
	position: absolute;
	background-color: #fff;
	color: #000;
	border-radius: 5px;
	padding: 0.5rem;
	opacity: 0;
	width: 100%;
	visibility: hidden;
	transform: translate(-50%, 100%);
	bottom: 0;
	left: 50%;
	transition:
		opacity 0.3s ease,
		transform 0.3s ease,
		visibility 0.3s;
	z-index: 10;
`;

export default Map;
