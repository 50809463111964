import StyledTable from '../../common/StyledTable';
import { useIntl } from 'react-intl';
import moment from 'moment';

export const ActiveQuotesDashboard = ({ data }) => {
	const { formatMessage } = useIntl();
	const columns = [
		{
			name: formatMessage({ id: 'table.folio' }),
			selector: row => row.quotation,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.company' }),
			selector: row => row.businessName,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.insuredAmount' }),
			selector: row =>
				`$${new Intl.NumberFormat('es-MX', { style: 'decimal', minimumFractionDigits: 2 }).format(row.montoFinanciamiento)}`,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.premiumAmount' }),
			selector: row =>
				`$${new Intl.NumberFormat('es-MX', { style: 'decimal', minimumFractionDigits: 2 }).format(row.primaTotal)}`,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.membershipType' }),
			selector: row => row.policeName,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.seller' }),
			selector: row =>
				`${row?.seller?.membership || '-'} - ${row?.seller?.name || '-'} ${row?.seller?.lastName || '-'}`,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.dateOfQuote' }),
			selector: row => moment(row.createdAt).format('DD/MM/YYYY'),
			sortable: true,
			wrap: true,
		},
	];

	return (
		<StyledTable
			data={data}
			columns={columns}
			progressPending={false}
			pagination
			paginationServer
			paginationTotalRows={data.length}
			onChangeRowsPerPage={() => {}}
			onChangePage={() => {}}
			noDataComponent={<div>No hay datos</div>}
			paginationPerPage={10}
		/>
	);
};
