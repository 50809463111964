const messages_es = {
	'login.welcome': 'Bienvenido, inicia sesión',
	'logout.title': 'Cerrar sesión',

	'commons.insurer': 'Aseguradora',
	'commons.reinsurer': 'Reasegurador',

	'commons.back': 'Regresar',
	'commons.save': 'Guardar',
	'commons.CreateQuote': 'CREAR COTIZACIÓN',
	'commons.validate': 'Validar',
	'commons.update': 'Actualizar',
	'commons.rowsPerPage': 'Filas por página',
	'commons.of': 'de',
	'commons.noData': 'No se encontraron registros',
	'commons.step': 'Paso',
	'commons.filter': 'Filtrar...',
	'commons.dragAndDrop': 'Da click o arrastra el archivo aquí',
	'commons.findNumber': 'Buscar número',
	'commons.initDate': 'Fecha inicial',
	'commons.endDate': 'Fecha final',
	'commons.downloadAll': 'Descargar todos',
	'commons.consolidated': 'Consolidado',
	'commons.status': 'Estatus',
	'commons.apply': 'Aplicar',
	'commons.credits': 'Créditos',
	'commons.next': 'Siguiente',
	'commons.download': 'Descargar',
	'commons.have': 'Tienes',
	'commons.error': 'error(es)',
	'commons.required': 'Campo requerido',
	'commons.textQuantity': 'Cantidad de caracteres:',
	'commons.start': 'Iniciar',
	'commons.daily': 'Diario',
	'commons.weekly': 'Semanal',
	'commons.biweekly': 'Quincenal',
	'commons.monthly': 'Mensual',
	'commons.company_supervisor': 'Supervisor',
	'commons.customer': 'Cliente',
	'commons.downloading': 'Descargando',
	'commons.root': 'root',
	'commons.add': 'Añadir',
	'commons.yes': 'Sí',
	'commons.contactUs': 'Contáctanos',
	'commons.suscribeMe': 'Suscribirme',
	'commons.goToPay': 'Ir al pago',
	'commons.mxn': ' ',
	'commons.customize': 'Personalizar',

	'month.enero': 'Enero',
	'month.febrero': 'Febrero',
	'month.marzo': 'Marzo',
	'month.abril': 'Abril',
	'month.mayo': 'Mayo',
	'month.junio': 'Junio',
	'month.julio': 'Julio',
	'month.agosto': 'Agosto',
	'month.septiembre': 'Septiembre',
	'month.octubre': 'Octubre',
	'month.noviembre': 'Noviembre',
	'month.diciembre': 'Diciembre',

	'table.name': 'NOMBRE',
	'table.lastName': 'APELLIDO',
	'table.email': 'CORREO',
	'table.phone': 'TELÉFONO',
	'table.membership': 'MEMBRESÍAS',
	'table.createdAt': 'MIEMBRO DESDE',
	'table.allySince': 'ALIADO DESDE',
	'table.guideSince': 'GUIA DESDE',
	'table.membershipType': 'TIPO DE MEMBRESÍA',
	'table.police': 'CERTIFICADO',
	'table.MEMBERSHIPPDF': 'CERTIFICADO PDF',
	'table.options': 'OPCIONES',
	'table.seller': 'GUÍA WILMA',
	'table.admin': 'ADMINISTRADOR',
	'table.status': 'ESTATUS',
	'table.actions': 'Acciones',
	'table.date': 'FECHA',
	'table.memberId': 'ID DE MIEMBRO',
	'table.guideId': 'ID DE GUÍA',
	'table.allyId': 'ID DE ALIADO',
	'table.ally': 'ALIADO WILMA',
	'table.discount': 'DESCUENTO',
	'table.company': 'MIEMBRO',
	'table.member': 'MIEMBRO',
	'table.insuredAmount': 'SUMA ASEGURADA',
	'table.premiumAmount': 'PRIMA TOTAL',
	'table.city': 'CIUDAD',
	'table.premium': 'PRIMA',
	'table.folio': 'FOLIO',
	'table.quotes': 'NÚMERO DE COTIZACIONES',
	'table.active.quotes': 'COTIZACIONES ACTIVAS',
	'table.inactive.quotes': 'COTIZACIONES INACTIVAS',
	'table.memberships': 'MEMBRESÍAS',
	'table.last.login': 'ÚLTIMO ACCESO',
	'table.dateOfCreation': 'FECHA DE CREACION',
	'table.dateOfQuote': 'FECHA DE COTIZACIÓN',
	'table.accionsTitle': 'ACCIONES',
	'table.reactive': 'Reactivar',
	'table.policyReactivated': 'Poliza reactivada',
	'table.policyReactivateError': 'Error al reactivar la poliza',
	'table.Active': 'ACTIVAS',
	'table.Inactive': 'INACTIVAS',

	'opt.delete': 'Eliminar',
	'opt.edit': 'Editar',
	'opt.credits': 'Cargar créditos',
	'opt.view': 'Ver',
	'opt.downloadReport': 'Descargar reporte',
	'opt.downloadAudio': 'Descargar audio',
	'opt.downloadFile': 'Descargar archivo',
	'opt.clone': 'Clonar campaña',
	'opt.moreThan100': 'Más de 100',
	'opt.to': 'a',
	'opt.businessLine.1': 'Hospedaje/Restaurantes y Eventos',
	'opt.businessLine.2': 'Transporte',
	'opt.businessLine.3': 'Tour Operador y Proveedor de Experiencias',
	'opt.businessLine.4': 'Servicios en Playa',
	'opt.businessLine.5': 'Renta de Propiedades Vacacionales',
	'opt.businessLine.6': 'Bienes Raices y Administración de Propiedades',
	'opt.businessLine.7': 'Cultura',
	'opt.businessLine.8': 'Servicios de Salud',
	'opt.businessLine.9': 'Asesoría',
	'opt.businessLine.10': 'Locales Comerciales',
	'opt.businessLine.12': 'Casa Habitación',
	'opt.businessLine.11': 'Otros',
	'opt.customized': 'PERSONALIZADO',
	'opt.select': 'Seleccionar',

	'dashboard.title': 'Dashboard',
	'dashboard.members': 'Miembros',
	'dashboard.addMember': 'Añadir miembro',
	'dashboard.sellers': 'Guías Wilma',
	'dashboard.addGuide': 'Añadir guía',
	'dashboard.memberships': 'Membresías',
	'dashboard.addQuote': 'Añadir cotización',
	'dashboard.addAlly': 'Añadir Aliado',
	'dashboard.Calculator': 'Calculadora',

	'police.limitText': 'Limite de indemnización de tu membresía',
	'police.premium': 'Prima',
	'police.expeditionAmount': 'Gastos de expedición',
	'police.iva': 'IVA',
	'police.resume': 'Resumen de su membresía',
	'police.contractor': 'Contratante',
	'forms.ChangePassword': 'Cambiar contraseña.',
	'forms.loginTitle': 'Inicia sesión',
	'forms.login': 'Iniciar sesión',
	'forms.welcomeMessage': 'Ingresa para retomar tus aplicaciones o administrar tus membresías.',
	'forms.userName': 'Nombre de usuario',
	'forms.userNameLabel': 'Ingresa tu nombre de usuario',
	'forms.name': 'Nombre',
	'forms.nameLabel': 'Ingresa tu nombre',
	'forms.lastName': 'Apellido',
	'forms.lastNameLabel': 'Ingresa tu apellido',
	'forms.gender': 'Selecciona tu género',
	'forms.age': 'Edad',
	'forms.ageLabel': 'Ingresa tu edad',
	'forms.whatsapp': 'Número de WhatsApp',
	'forms.whatsappLabel': 'Ingresa tu número de WhatsApp',
	'forms.email': 'Correo',
	'forms.emailLabel': 'Ingresa tu correo',
	'forms.EnterYourEmailAddress': 'Ingresa tu correo electrónico',
	'forms.password': 'Contraseña',
	'forms.passwordLabel': 'Ingresa tu contraseña',
	'forms.confirmPassword': 'Confirma tu contraseña',
	'forms.EnterYourNewPassword': 'Ingresa tu nueva contraseña.',
	'forms.forgetPassword': 'Recuperar / Cambiar contraseña',
	'forms.wilmaGuide': 'Guía Wilma (opcional)',
	'forms.wilmaGuideLabel': 'Ingresa el código de tu guía Wilma',
	'forms.register': 'Registrarme',
	'add.register': 'Registrar',
	'forms.newUser': 'Nuevo usuario',
	'forms.tellUs': 'Cuéntanos más sobre ti',
	'forms.nameCorporate': 'Nombre o razón social',
	'forms.contractor': 'Nombre del contratante',
	'forms.contractorLabel': 'Ingresa el nombre del contratante',
	'forms.streetAndNumber': 'Calle y número',
	'forms.streetAndNumberLabel': 'Ingresa tu calle y número',
	'forms.state': 'Estado',
	'forms.companyCity': 'Población/municipio',
	'forms.companyCityLabel': 'Ingresa tu población/municipio',
	'forms.colony': 'Colonia',
	'forms.colonyLabel': 'Ingresa tu colonia',
	'forms.zipCode': 'Código Postal',
	'forms.zipCodeLabel': 'Ingresa tu código postal',
	'forms.rfc': 'RFC',
	'forms.rfcLabel': 'Ingresa tu RFC',
	'forms.activity': 'Actividad',
	'forms.businessLine': 'Selecciona tu actividad',
	'forms.commercialName': 'Nombre comercial',
	'forms.commercialNameLabel': 'Ingresa tu nombre comercial',
	'forms.numberOfEmployees': 'Número de empleados',
	'forms.largeRevenue': '¿Tus ingresos por facturación anual son superiores a $235,000,000?',
	'forms.largeRevenueM1':
		'Nuestra membresía esta enfocada en ayudar al sector PYME, por favor contacta a uno se nuestros asesores para hacerte una solución a la medida.',
	'forms.largeRevenueM2':
		'Si te interesa adquierir una de nuestras membresías, contáctanos para poderte brindar más información.',
	'forms.largeRevenueM3': 'Puedes dejar en blanco la contraseña si no es necesario modificarla',
	'forms.chooseMembership': 'Escoge una de nuestras membresías',
	'forms.selectCity': 'Selecciona una ciudad',
	'forms.selectMembership': 'Selecciona un tipo de membresía',
	'forms.MINIMUMAMOUNT': 'MONTO MÍNIMO',
	'forms.MAXIMUMAMOUNT': 'MONTO MAXIMO',
	'forms.compensationLimit': 'Monto de indemnización',
	'forms.compensationLimitLabel': 'Limite de Indemnización de tu membresía',
	'forms.recover': 'Recuperar',
	'forms.selectSeller': 'Guia',
	'forms.partner': 'Aliado',
	'forms.partnerLabel': 'Ingresa al aliado asignado',
	'forms.capacity': 'Capacidad',
	'forms.capacityLabel': 'Ingresa capacidad',
	'forms.netPremium': 'Meta Prima Neta',
	'forms.netPremiumlabel': 'Ingresa Prima Neta',
	'forms.memberships': 'Meta membresias',
	'forms.membershipsLabel': 'Ingresa Membresias',
	'forms.registerForm': 'Regristrar',
	'forms.editGuide': 'Editar guía',
	'forms.editAlly': 'Editar aliado',
	'forms.editMember': 'Editar miembro',

	'errors.invalidNumber': 'Debe ser un número válido',
	'errors.invalidEmail': 'Debe ser un correo válido',
	'errors.invalidPassword': 'La contraseña debe contener al menos 6 caracteres',
	'errors.required': 'Este campo es requerido',
	'errors.minimun': 'El monto mínimo es de $100,000',
	'errors.maximum': 'El monto máximo es de $7,000,000',

	'label.male': 'Masculino',
	'label.female': 'Femenino',
	'label.other': 'Prefiero no decirlo',

	'msg.termsAndConditions':
		'Confirmo, bajo protesta decir verdad, que los datos son correctos y que el Contratante tiene bienes o una actividad económica que puede ser afectada por un Huracán dentro del Área de Cobertura seleccionada.',
	'msg.forgotPasswordTitle': 'Recuperar / Cambiar contraseña',
	'msg.forgotPassword':
		'Ingresa tu correo y te enviaremos una liga para Recuperar o cambiar tu contraseña',
	'msg.newUserTitle': '¡Únete a la comunidad Wilma!',
	'msg.newUser': 'Ingresa los siguientes datos',
	'msg.shortTerms': 'Acepto los términos y condiciones',
	'msg.seeTerms': 'Ver términos y condiciones',

	'account.title': 'Mi cuenta',
	'account.membership': 'Mis membresías',
	'account.noMembership': 'No tienes suscripciones activas',
	'account.completeProcess': 'Completar proceso',
	'account.noProcess': 'No tienes procesos incompletos',
	'account.welcome': 'Bienvenido',
	'account.validFrom': 'Válida desde',
	'account.validUntil': 'Válida hasta',
	'account.policy': 'Póliza',

	'members.title': 'Miembros',

	'guides.title': 'Guías Wilma',

	'memberships.title': 'Membresías',

	'allies.title': 'Aliados',

	'quotes.title': 'Cotizaciones',

	'payments.manual': 'Pago Manual',

	'claims.title': 'Siniestros',

	'payment.over': 'Paga hasta en ',
	'payment.months': 'meses sin intereses',
	'payment.with': 'con TC',

	'dashboard.clipboardLink': '¡Link copiado al portapapeles!',
	'dashboard.clipboardBottom': '¡Copia el link de referente!',

	'message.YouMustConfirmThatTheDataIsCorrect': 'Debes confirmar que los datos son correctos.',
	'message.AnEmailHasBeenSentWithTheLinkToResetYourPassword':
		'Se ha enviado un correo con la liga para recuperar tu contraseña.',
	'message.RequiredField': 'Campo requerido.',
	'message.Minimum10Characters': 'Mínimo 10 caracteres.',
	'message.Maximum10Characters': 'Máximo 10 caracteres',
	'message.TheEmailIsAlreadyRegistered': 'El correo ya está registrado.',
	'message.AnErrorOccurredWhileSendingTheEmailPleaseTryAgain':
		'Ocurrió un error al enviar el correo, por favor intenta de nuevo.',
	'message.InvalidEmail': 'Correo inválido',
	'message.Minimum6Characters': 'Mínimo 6 caracteres.',
	'message.ThePasswordsMustMatch': 'Las contraseñas deben coincidir.',
	'message.OnlyNumbersAreAllowed': 'Solo se permiten números.',
	'message.GuideCreatedSuccessfully': 'Guía creado exitosamente.',
	'message.UnexpectedError': 'Error inesperado',
	'message.ReferenceNotFound': 'Referencia no encontrada',
	'message.ThePolicyIsAlreadyActive': 'La póliza ya está activa.',
	'message.EnterThePaymentID': 'Ingresa el ID del pago.',
	'message.PaymentID': 'ID del pago.',
	'message.MemberEditedSuccessfully': 'Miembro editado correctamente.',
	'message.PasswordSuccessfullyChangedPleaseLogIn':
		'Contraseña cambiada exitosamente. Inicia sesión.',
	'message.AnErrorHasOccurredPleaseTryAgain.': 'Ocurrió un error, intenta de nuevo.',
	'message.PaymentUpdatedSuccessfully': 'Pago actualizado correctamente.',
	'message.WilmaMemberCreatedSuccessfully': 'Miembro Wilma creado correctamente.',
	'message.QuotationSaved': 'Cotización guardada.',
	'message.TheProvidedEmailIsAlreadyRegisteredButNotAsAcustomer':
		'El correo proporcionado ya está registrado pero no como cliente.',
	'message.ThePhoneNumberIsAlreadyRegistered': 'El teléfono ya está registrado.',
	'messagae.IncorrectGuidePleaseContactWilma': 'Guía incorrecto, contacta a Wilma.',
	'message.ErrorGeneratingPaymentLink': 'Error al generar link de pago.',
	'title.NetPremiumTarget': 'OBJETIVO PRIMA NETA',
	'title.MembershipTarjet': 'OBJETIVO MEMBRESÍAS',
	'title.TarjetInsuredAmount': 'OBJETIVO SUMA ASEGURADA',
	'dashboard.membershipSold': 'MEMBRESÍAS COLOCADAS',
	'dashboard.ALLIES': 'ALIADOS',
	'dashboard.GUIDES': 'GUÍAS',
	'dashboard.MEMBERS': 'MIEMBROS',
	'dashboard.QUOTES': 'COTIZACIONES',
	'members.createMember': 'CREAR MIEMBRO',
};

export default messages_es;
