import { useDataTable } from '../../../hooks/useDataTable';
import StyledTable from '../../common/StyledTable';
import DownloadPolice from '../../common/DownloadPolice';
import DownloadReport from '../../common/DownloadReport';
import Grid from '../../common/Grid';
import { useIntl } from 'react-intl';
import userService from 'services/userService';
const MembershipsTable = () => {
	// sacamos el allyId de local storage
	const allyId = localStorage.getItem('allyId');
	// params para la busqueda
	const params = [{ name: null, value: null }];
	// si viene el allyId lo agregamos a los params
	if (allyId) {
		params.push({ name: 'selectedUser', value: allyId });
	}

	params.push({ name: 'status', value: 'Activa' })

	const { isLoading, response, totalDocs, perPage, handlePageChange, handlePerRowsChange } =
		useDataTable('police/search', params);
	const userRole = userService.getRole();

	const { formatMessage } = useIntl();
	const columns = [
		{
			name: formatMessage({ id: 'table.membership' }),
			selector: row => row.police,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.company' }),
			selector: row => row.businessName,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.insuredAmount' }),
			selector: row => `$${new Intl.NumberFormat('es-MX', { style: 'decimal', minimumFractionDigits: 2 }).format(row.montoFinanciamiento)}`,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.premiumAmount' }),
			selector: row => `$${new Intl.NumberFormat('es-MX', { style: 'decimal', minimumFractionDigits: 2 }).format(row.primaTotal)}`,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.membershipType' }),
			selector: row => row.policeName,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.seller' }),
			selector: row =>
				`${row?.seller?.membership || '-'} - ${row?.seller?.name || '-'} ${row?.seller?.lastName || '-'}`,
			sortable: true,
			wrap: true,
		},

		{
			name: formatMessage({ id: 'table.MEMBERSHIPPDF' }),
			selector: row => <DownloadPolice id={row._id} />,
			sortable: true,
			grow: 1,
			wrap: true,
		},
	];

	return (
		<>
			<StyledTable
				data={response}
				columns={columns}
				progressPending={isLoading}
				pagination
				paginationServer
				paginationTotalRows={totalDocs}
				onChangeRowsPerPage={handlePerRowsChange}
				onChangePage={handlePageChange}
				noDataComponent={<div>No hay datos</div>}
				paginationPerPage={perPage}
			/>
			{userRole === 'root' && (
				<Grid>
					<DownloadReport
						justify="flex-end"
						endpoint="/police/police-csv"
						name={formatMessage({ id: 'commons.insurer' })}
					/>
					<DownloadReport
						justify="flex-end"
						endpoint="/police/police-csv-short"
						name={formatMessage({ id: 'commons.reinsurer' })}
					/>
				</Grid>
			)}
		</>
	);
};
export default MembershipsTable;
