import { useDashboardData } from '../../hooks/useDashoardData';
import Goals from 'components/dashboards/goals';
import Grid from 'components/common/Grid';
import Skeleton from 'components/common/Skeleton';
import Map from 'components/dashboards/map';
import LinkCreator from 'components/dashboards/linkCreator';
import { useEffect, useState } from 'react';
import Content from 'components/common/Content-v2';
import { DashboardTable } from 'components/tables/dashboard';
import { BreadCrumbs } from 'components/common/BreadCrumbs';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { ActiveQuotesDashboard } from 'components/tables/quotes/ActiveQuotesDashboard';
import { OverdueQuotesDashboard } from 'components/tables/quotes/OverdueQuotesDashboard';
const Dashboard = () => {
	const [refresh, setRefresh] = useState(null);
	const [type, setType] = useState('active');
	const [role, setRole] = useState(localStorage.getItem('role') ?? '');
	const [extraParams, setExtraParams] = useState([{ name: null, value: null }]);
	const { isLoading, response } = useDashboardData(extraParams, refresh);
	const [selectedUser, setSelectedUser] = useState(localStorage.getItem('selectedUser') ?? '');
	const { formatMessage } = useIntl();

	useEffect(() => {
		if (role && !selectedUser) {
			setExtraParams([{ name: 'roleFilter', value: role }]);
			setRefresh(new Date().getTime());
		} else if (role && selectedUser) {
			setExtraParams([
				{ name: 'roleFilter', value: role },
				{ name: 'selectedUser', value: selectedUser },
			]);
			setRefresh(new Date().getTime());
		} else if (!role && selectedUser) {
			console.log('selectedUser', selectedUser);
			setExtraParams([{ name: 'selectedUser', value: selectedUser }]);
			setRefresh(new Date().getTime());
		} else if (!role && !selectedUser) {
			setExtraParams([{ name: null, value: null }]);
			setRefresh(new Date().getTime());
		}
	}, [role, selectedUser]);

	return (
		<>
			{isLoading && (
				<Grid columns="1">
					<Skeleton count={30} />
				</Grid>
			)}
			{!isLoading && (
				<>
					<BreadCrumbs
						userLevels={{
							roleLv1: response.roleLv1,
							nameUserLv1: response.nameUserLv1,
							roleLv2: response.roleLv2,
							nameUserLv2: response.nameUserLv2,
							idLv2: response.idLv2,
							roleLv3: response.roleLv3,
							nameUserLv3: response.nameUserLv3,
							idLv3: response.idLv3,
							roleLv4: response.roleLv4,
							nameUserLv4: response.nameUserLv4,
						}}
						setSelectedUser={setSelectedUser}
						setRole={setRole}
					/>
					<LinkCreator />
					<Goals
						{...response}
						role={role}
					/>
					<Map
						{...response}
						setRole={setRole}
						role={role}
					/>
					{!isLoading && response.section.length && role !== 'quotes' ? (
						<Content separator="2rem">
							<DashboardTable
								users={response.section}
								setRole={setRole}
								setSelectedUser={setSelectedUser}
							/>
						</Content>
					) : !isLoading && response.section[0].length && role === 'quotes' ? (
						<>
							<Tabs>
								<Tab
									className={type === 'active' ? 'active' : ''}
									onClick={() => setType('active')}>
									{formatMessage({ id: 'table.Active' })}
								</Tab>
								<Tab
									className={type === 'overdue' ? 'active' : ''}
									onClick={() => setType('overdue')}>
									{formatMessage({ id: 'table.Inactive' })}
								</Tab>
							</Tabs>
							<Content borderRadius=" 0 .75rem  .75rem .75rem">
								{type === 'active' && (
									<ActiveQuotesDashboard data={response.section[0].map(doc => doc._doc)} />
								)}
								{type === 'overdue' && (
									<OverdueQuotesDashboard data={response.section[1].map(doc => doc._doc)} />
								)}
							</Content>
						</>
					) : null}
				</>
			)}
		</>
	);
};
export default Dashboard;

export const Tabs = styled.div`
	display: flex;
	margin-top: 20px;
`;

export const Tab = styled.div`
	font-size: 1.2em;
	padding: 0.5em 1em 0.5em 1em;
	border-radius: 5px 5px 0 0;
	background-color: ${props => props.theme.menuLines};
	cursor: pointer;
	&.active {
		background: ${props => props.theme.background};
	}
`;
