import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Input from 'components/common/forms/Input';
import Submit from 'components/common/forms/Submit';
import Grid from 'components/common/Grid';
import { useIntl } from 'react-intl';

const ManualPaymemtForm = props => {
	let navigate = useNavigate();
	const { formatMessage } = useIntl();
	return (
		<Formik
			initialValues={{
				paymentId: '',
			}}
			validationSchema={Yup.object({
				paymentId: Yup.string().required(formatMessage({ id: 'message.RequiredField' })),
			})}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				setSubmitting(true);
				api
					.get(`/mercado-pago/payment-info?paymentId=${values.paymentId}`)
					.then(res => {
						toast.success(formatMessage({ id: 'message.PaymentUpdatedSuccessfully' }));
						resetForm();
						setSubmitting(false); // Asegúrate de cambiar el estado de envío después de una respuesta exitosa
					})
					.catch(err => {
						console.log(err);

						// Comprobamos si hay una respuesta y un mensaje de error específico
						if (err.response?.data?.message === 'Rerencia no encontrada') {
							toast.error(formatMessage({ id: 'message.ReferenceNotFound' })); // Corrige el error tipográfico
						} else if (err.response?.data?.message === 'La póliza ya está activa') {
							toast.error(formatMessage({ id: 'message.ThePolicyIsAlreadyActive' }));
						} else {
							toast.error(formatMessage({ id: 'message.UnexpectedError' }));
						}

						setSubmitting(false);
					});
			}}>
			{formik => (
				<>
					<MyForm onSubmit={formik.handleSubmit}>
						<Grid columns="1">
							<Input
								name="paymentId"
								type="text"
								label={formatMessage({ id: 'message.PaymentID' })}
								placeholder={formatMessage({ id: 'message.EnterThePaymentID' })}
							/>
						</Grid>
						<Submit
							type="submit"
							margin="3em auto 0 auto "
							disabled={formik.isSubmitting}>
							{formatMessage({ id: 'add.register' })}
						</Submit>
					</MyForm>
				</>
			)}
		</Formik>
	);
};

const MyForm = styled.form`
	padding: 1em 0 2em 0;
	width: 100%;
`;

export default ManualPaymemtForm;
